import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faSquarePlus } from '@fortawesome/free-solid-svg-icons'; // pro: faPenCircle, faCirclePlus

import Alert from '../components/Alert';
import Page from '../components/Page';
import PageTitle from '../components/PageTitle';
import Preloader from '../components/Preloader';
import IfLoaded from '../components/IfLoaded';
import Teaser from '../components/Teaser';

import { routes } from '../navigation';
import { Styles } from '../styling';
import api from '../api';

import { mockAlbums } from '../mock-data';


const Albums = function() {
    const [albums, setAlbums] = useState([]);
    const [error, setError] = useState(null);

    const isLoading = () => !(albums.items?.length || error);
    const size = 'w-full md:w-80';

    useEffect(() => {
        
        
        api.mock(mockAlbums)  // Replace with actual API call when available
        //api.albums()
            .then(res => setAlbums(res))
            .then(console.log(albums))
            .catch(error => setError(error));
    }, []);


    console.log(JSON.stringify(albums, null, 2));
    return (
        <Page.Simple>
            <PageTitle route={ routes.albums } />
            <Preloader loading={ isLoading() } message="fetching contents" />
            <Alert.Error message={ error } />
            <IfLoaded loading={ isLoading() }>
                <ul className="grid grid-cols-1 md:grid-cols-2 gap-4 auto-rows-fr">
                {
                    // Ensure the items array exists before attempting to map over it
                    albums?.items?.map((entry, index) => (
                        <li key={ index } className="rounded-lg">
                            <Teaser.Default
                                className="bg-reminu--grey-6 hover:bg-reminu--grey-3 group"
                                image={ entry.image ? entry.image : '/assets/default-album-image_50.png' }  // Handle null image
                                size={ size }
                                inner="flex flex-col gap-4 p-6"
                            >
                                <Link className="block w-full h-full flex flex-col gap-4" to={ routes.albumPages.path(0) }>
                                    <div className="grow">
                                        <h2 className={ Styles.h2 }>{ entry.name }</h2>
                                    </div>
                                    <div className="my-0 md:my-4 invisible group-hover:visible">
                                        <FontAwesomeIcon icon={ faPen } aria-hidden="true" className="pr-2" /> Edit
                                    </div>
                                </Link>
                            </Teaser.Default>
                        </li>
                    ))

                }
                </ul>

                <aside className="mt-6 md:mt-20 text-center">
                    <Link
                        to={ routes.albumEdit.path('new') }
                        className="inline-block width-auto px-8 py-4 border rounded-lg border-reminu--darker text-reminu--darker opacity-20 hover:opacity-80"
                    >
                        <FontAwesomeIcon icon={ faSquarePlus } aria-hidden="true" className="pr-2" /> Add new album
                    </Link>
                </aside>

            </IfLoaded>
        </Page.Simple>
    );
};


export default Albums;
