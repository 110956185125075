import React, { useEffect } from "react";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import Transloadit from "@uppy/transloadit";
import Webcam from "@uppy/webcam";
import Audio from "@uppy/audio";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

const FileUploader = ({ onComplete }) => {
  const uppy = new Uppy({
    restrictions: { maxNumberOfFiles: 10 },
    autoProceed: true,
  });

  useEffect(() => {
    uppy.use(Transloadit, {
      params: {
        auth: { key: "03507a436ee6409c97a23e3e961b8b25" }, // Replace with your Transloadit auth key
        steps: {
          ':original': { robot: '/upload/handle' },
          exported: {
            use: ':original',
            robot: '/digitalocean/store',
            credentials: 'recordaDO',
            path: 'media/${file.id}.${file.ext}',
          },
        },
      },
      waitForEncoding: true,
    })
    .use(Webcam, { target: '#uppyA' }) // Adjust the target to a valid DOM element
    .use(Audio, { target: '#uppyA' }) // Adjust the target to a valid DOM element
    .on("transloadit:complete", (assembly) => {
      const files = assembly.results[':original'];
      const fileData = files.map((file) => ({
        id: file.id,
        mime: file.mime,
        ext: file.ext,
        ssl_url: file.ssl_url,
      }));
      onComplete(fileData);
    });

    // Return cleanup function to close Uppy instance
    return () => {
    };
  }, [uppy, onComplete]);

  return (
    <div>
      {/* Dashboard must target an actual DOM element */}
      <Dashboard uppy={uppy} id="uppyA" />
    </div>
  );
};

export default FileUploader;
