import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import Alert from '../components/Alert';
import Page from '../components/Page';
import PageTitle from '../components/PageTitle';
import Preloader from '../components/Preloader';
import IfLoaded from '../components/IfLoaded';
import Teaser from '../components/Teaser';

import { routes } from '../navigation';
import { classNames, Styles } from '../styling';
import api from '../api';

import { FilteredSearch } from '../components/Search';
import { mockGuidedPlans, mockFeaturedPlan, mockFilters } from '../mock-data';

const { REACT_APP_API_ENDPOINT } = process.env;
const API_ENDPOINT = REACT_APP_API_ENDPOINT.replace(/\/$/, '');
const _url = (path) => `${API_ENDPOINT}${path}`;

const Wizard = function({ className, title,  children }) {
    if (!title) {
        return (null);
    }

    return (
        <aside className={ classNames('block', className) }>
            <h3 className="mb-4 uppercase font-bold">{ title }</h3>
            <div>{ children }</div>
        </aside>
    );
};

const GuidedPlans = function() {
    const [guidedPlans, setGuidedPlans] = useState([]);
    const [featuredPlan, setFeaturedPlan] = useState([]);
    const [filters, setFilters] = useState([]);
    const [error, setError] = useState(null);

    const isLoading = () => !(guidedPlans.items?.length || error);

    useEffect(() => {
        Promise.all([
            api.mock(mockFeaturedPlan),
            api.mock(mockGuidedPlans),
            api.mock(mockFilters)
        ])
            .then((values) => {
                setFeaturedPlan(values[0]);
                setGuidedPlans(values[1]);
                setFilters(values[2]);
            })
            .catch(error => setError(error));
    }, []);

    // grid
    const grid = 'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mb-4';
    const column = '';
    const columnXl = 'hidden md:block xl:col-span-2';

    // w-32:  8rem, 128px
    // w-52: 13rem, 208px
    // w-64: 16rem, 256px
    // w-80: 20rem, 320px

    // top teaser (height, image width)
    const topHeight = 'h-auto max-h-64';
    const topWidth = 'w-full md:w-32 lg:w-80';

    // bottom teasers (height, image width)
    const bottomHeight = 'h-auto max-h-52';
    const bottomWidth = 'w-auto md:w-auto md:max-w-52';

    return (
        <Page.Simple>
            <PageTitle route={ routes.guidedPlans } />
            <Preloader loading={ isLoading() } message="fetching contents" />
            <Alert.Error message={ error } />

            <IfLoaded loading={ isLoading() }>
                <section className={ grid }>

                    <Teaser.Default
                        image={ featuredPlan.image }
                        className={ classNames(column, 'bg-reminu--grey-2 group') }
                        size={ classNames(topWidth, topHeight) }
                    >
                        <div className="block w-full h-full flex flex-col">
                            <div className="grow">
                                <h2 className={ classNames(Styles.h2, 'md:text-base md:font-bold') }>{ featuredPlan.name }</h2>
                                <summary className="mb-2">
                                    { featuredPlan.description }
                                </summary>
                            </div>
                            <div className="mb-4">
                                <Link className={ classNames(Styles.btnRoundedSmall, 'hover:bg-reminu--light') } to={ routes.guidedPlanEdit.path(1) }>Start</Link>
                            </div>
                        </div>
                    </Teaser.Default>

                    <Wizard title={ (featuredPlan) ? 'Wizard heading' : '' } className={ classNames(columnXl, topHeight, 'p-8 overflow-auto') }>
                        Nullam quis risus eget urna mollis ornare vel eu leo. Vestibulum id ligula porta felis euismod semper.
                        Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                    </Wizard>
                </section>

                <FilteredSearch
                    className={ classNames(grid, 'col-span-3') }
                    searchClassName="border-reminu--dark"
                    textButton
                    filters={ filters }
                />

                <ul className={ grid }>
                {
                    guidedPlans.items?.map((entry, index) => (
                        <li key={ index }>
                            <Teaser.Strict
                                image = { entry.image?.web_path ? _url(entry.image.web_path) : '/assets/default-guidedplan-image_50.png' }

                                //image={ entry.image.web_path }
                                className={ classNames(column, bottomHeight, 'bg-reminu--grey-6 group') }
                                size={ classNames(bottomWidth, bottomHeight) }
                            >
                                <div className="block w-full h-full flex flex-col">
                                    <div className="grow">
                                        <h2 className={ classNames(Styles.h2, 'md:text-base md:font-bold') }>{ entry.name }</h2>
                                        <summary className="mb-2 hidden lg:block">
                                            { entry.desc }
                                        </summary>
                                    </div>
                                    <div className="mb-4">
                                        <Link className={ classNames(Styles.btnRoundedSmall, 'hover:bg-reminu--light') } to={ routes.guidedPlanEdit.path(entry.id) }>Start</Link>
                                    </div>
                                </div>
                            </Teaser.Strict>
                        </li>
                    ))
                }
                </ul>
            </IfLoaded>
        </Page.Simple>
    );
};


export default GuidedPlans;
