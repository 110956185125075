import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleRight } from '@fortawesome/free-regular-svg-icons';

import Alert from '../components/Alert';
import Page from '../components/Page';
import PageTitle from '../components/PageTitle';
import Preloader from '../components/Preloader';
import IfLoaded from '../components/IfLoaded';
import Teaser from '../components/Teaser';
import Select from '../components/Select';
import FileUploader from '../forms/FileUploader'; // Import the FileUploader component

import PromptForm from '../forms/PromptForm';

import { routes } from '../navigation';
import { classNames, Styles } from '../styling';
import { timeout } from '../utils';

import useWindowDimensions from '../hooks/useWindowDimensions';
import api from '../api';

const { REACT_APP_API_ENDPOINT } = process.env;
const API_ENDPOINT = REACT_APP_API_ENDPOINT.replace(/\/$/, '');
const _url = (path) => `${API_ENDPOINT}${path}`;

const Details = function({ guidedPlan, className }) {
    if (!guidedPlan) {
        return (null);
    }

    const teaserImgWidth = 'w-12/12 md:w-6/12'; // md:50%
    const teaserImgHeight = 'h-full';

    return (
        <Teaser.Default
            image={ guidedPlan.image?.web_path ? _url(guidedPlan.image.web_path) : '/assets/default-guidedplan-image_50.png' }
            className={ classNames(className, 'bg-reminu--grey-2') }
            size={ classNames(teaserImgWidth, teaserImgHeight) }
        >
            { guidedPlan.desc }
        </Teaser.Default>
    );
};

const PromptLink = function ({ prompt, isActive, setActivePrompt, as }) {
    if (!prompt) {
        return (null);
    }

    const Component = as;

    const cls = {
        wrapper: (isActive) ? 'text-reminu--darker font-bold': 'text-reminu--grey-1',
        button: (isActive) ? 'block': 'hidden',
    }

    return (
        <Component key={ prompt.id } className={ classNames( cls.wrapper, 'hover:text-reminu--darker group flex justify-between mb-2 py-2 border-b') }>
            <button className="block text-left pr-4" onClick={
                (e) => {
                    e.preventDefault();
                    setActivePrompt(prompt);
                }
            }>{ prompt.prompt }</button>
            <FontAwesomeIcon className={ classNames(cls.button, 'z-10 group-hover:block') } style={{ marginRight: '-0.5em' }} icon={ faCircleRight } />
        </Component>
    );
};

const Prompts = function({ prompts, activePrompt, setActivePrompt, className }) {
    const { height, width } = useWindowDimensions();

    if (!prompts || !activePrompt) {
        return (null);
    }

    // breakpoint: md 768px @see https://v2.tailwindcss.com/docs/responsive-design
    if (width < 768) {
        return (
            <Select
                items={ prompts }
                getItemName={ item => item.prompt }
                activeItem={ activePrompt.id }
                setActiveItem={ setActivePrompt }
            />
        )
    }

    return (
        <ul className={ classNames(className) }>
            {
                prompts.map((prompt, index) => (
                    <PromptLink
                        key={ index }
                        prompt={ prompt }
                        isActive={ prompt.id === activePrompt.id }
                        setActivePrompt={ setActivePrompt }
                        as="li"
                    />
                ))
            }
        </ul>
    );
};

const GuidedPlan = function() {
    const { plan_id } = useParams(); // Get the ID from the URL
    console.log("Plan ID from URL:", plan_id);  // Add this line to see what value is captured
    
    const [guidedPlan, setGuidedPlan] = useState(null);
    const [prompts, setPrompts] = useState([]);
    const [activePrompt, setActivePrompt] = useState(null); // active prompt

    const [status, setStatus] = useState(null);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const notify = function(status, message, millisec=0) {
        setMessage(message);
        setStatus(status);

        if (millisec > 0) {
            return timeout(() => {
                setMessage(null);
                setStatus(null);
            }, millisec);
        }

        return Promise.resolve(true);
    };

    const finalize = function(status, message, millisec=0) {
        setLoading(false);
        notify(status, message, millisec);
    };

    const blockLeftPadding = 'p-6 md:py-6 md:pl-6 md:pr-0'; // left column without right padding (large), @see Styles.pagePadding

    useEffect(() => {
        setLoading(true);
        api.guidedplan(plan_id) // Use the ID from the URL
            .then(data => {
                const guidedPlan = data.items[0];
                setGuidedPlan(guidedPlan);
                return guidedPlan.guidedplan_prompt_items; // Directly return the prompt items
            })
            .then(promptItems => {
                setPrompts(promptItems);
                return promptItems.length ? promptItems[0] : null;
            })
            .then(firstPrompt => {
                setActivePrompt(firstPrompt);
                finalize('info', `Guided plan loaded`, 2000);
            })
            .catch(error => finalize('error', error.message));
    }, [plan_id]); // Dependency array includes id

    // Handle completion of the file upload process
    const handleUploadComplete = async (fileData) => {
        try {
            const response = await fetch("/json_upload_media", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(fileData),
            });
            const result = await response.json();
            console.log(result.message);
        } catch (error) {
            console.error("Error uploading media:", error);
        }
    };

    return (
        <Page.Plain className="h-full">
            <Preloader loading={ loading } message="fetching contents" />
            <Alert.Any status={ status } message={ message } />

            <IfLoaded loading={ loading }>
                <div className="h-full grid grid-cols-3 content-stretch"> { /* no gap, see layouts */ }

                    <section className={ classNames('col-span-3 md:col-span-1', blockLeftPadding, 'md:bg-reminu--grey-5')  }>
                        <PageTitle route={ routes.guidedPlans } />

                        <Details
                            guidedPlan={ guidedPlan }
                            className="mb-8"
                        />

                        <Prompts
                            guidedPlan={ guidedPlan }
                            prompts={ prompts }
                            activePrompt = { activePrompt }
                            setActivePrompt={ setActivePrompt }
                        />

                    </section>

                    <section className={ classNames('col-span-3 md:col-span-2', Styles.pagePadding) }>
                        <PromptForm
                            guidedPlan={ guidedPlan }
                            prompt={ activePrompt }
                        />
                        
                        {/* Adding the file uploader here */}
                        <FileUploader onComplete={handleUploadComplete} />
                    </section>

                </div>

            </IfLoaded>
        </Page.Plain>
    );
};

export default GuidedPlan;
